// 客户管理
.csrManageNo {
  display: flex;
  justify-content: flex-start;
  .csrManageNoOne {
    width: 100px;
  }
}

.add-customer {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
    .add-customerBtn {
      margin-left: 15px;
    }
}

.closeStyBtn {
  display: flex;
  width:  100%;
  justify-content: space-between;
  align-items: center;
}

// 客户管理公共样式
.customerSty {
  .CustomerLable{
    .ant-form-item-label {
      text-align: left
    }
  }
  .ant-upload.ant-upload-drag {
    width: 50%;
  }
  textarea.ant-input {
    max-width: 70%
  }
}

// 新增客户
.customerLine {
  height: 1px;
  background: #f0f2f5;
  width: calc(100% + 40px);
  margin-left: -20px;
}

// 客户变更
.customerChangeSty {
  .ant-tabs {
    overflow: visible;
  }
}

// 客户详情
.detailSty {
  margin: 20px 10px 10px 0;
}
.headData-Balance {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

// 客户新增审批
.customerAdd-hintTitle {
  display: flex;
  align-items: center;

  .hintText {
    margin-left: 12px;
  }
}

// 厂商id转移
.idTransfer {
  .ant-descriptions {
    margin-bottom: 0;
  }
}

// 批量厂商id转移
.table-BatchAdd {
  padding: 18px 0;
}

// 分配负责人
.add-SalesBtn {
  margin-left: 15px;
}

.csr-management-form {
  .ant-select-focused{
    .closeStyBtn{
      .closeSpan{
        display: none !important;
      }
    }
  }
}

// 信用账户统计
.creditStatistics-top {
  display: flex;
  justify-content: space-between;
  margin: 16px 5px;
  font-size: 15px;
  font-weight: 700;
  color: black;
  .creditStatistics-top-title {
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .creditStatistics-top-name {
    max-width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.table-public .ant-table-fixed-left table,
.table-public .ant-table-fixed-right table{
  width: min-content !important;
  }